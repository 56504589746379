<template>
	<v-col class="mx-auto fill-height" :class="$vuetify.breakpoint.width < 600 ? 'px-0 pt-1 pb-0':'pa-0'" ref="container">
		<div v-if="base_widgets !== null && base_widgets.length > 0 && ready">
			<div v-if="fixed_row" v-show="!drawer_widgets" style="position: sticky; top: 40px; z-index: 2" class="pt-3 px-3 background">
				<v-row align="center" class="mx-0">
					<v-col cols="auto" class="pt-0 pr-2 pb-1 px-0">
						<Button :click="() => fixed_row.open = !fixed_row.open" icon iconSize="22" carre iconVal="mdi-chevron-up" :classes="fixed_row.open ? '':'transition-rotate'" />
					</v-col>
					<v-col class="pl-0 pt-0 pb-1">
						<div class="paragraph">{{ fixed_row.title }}</div>
					</v-col>
				</v-row>
				<v-expand-transition>
					<div
							v-show="fixed_row.open"
							class="gridcontainer transition-cubic text-center mb-3"
							:style="{
								gridTemplateColumns: 'repeat('+ cols + ', '+ unit +'px)',
								gridTemplateRows: unit + 'px',
								gridAutoRows: unit + 'px'
							}"
					>
						<Widget
								v-for="widget in fixed_row.items"
								:key="widget.id"
								:edit="drawer_widgets"
								:widget="widget"
								:cols="cols"
						/>
					</div>
				</v-expand-transition>
				<v-divider class="mx-2 mt-1" v-if="fixed_row && rows.length > 1" v-show="!drawer_widgets" />
			</div>

			<v-row v-show="!drawer_widgets && rows[0].items.length === 0" class="py-8">
				<v-col cols="12" class="text-center title-2">{{ $t('single_trad.Home.welcome') }}</v-col>
				<v-col cols="12" class="text-center sub-title-2">
					{{ $t('single_trad.Home.sub_title') }}
					<v-icon size="20">mdi-cog</v-icon>
					{{ $t('single_trad.Home.sub_title2') }}
				</v-col>
			</v-row>

			<draggable
					v-model="rows"
					handle=".handle2"
					ghost-class="ghost"
					:class="$vuetify.breakpoint.width < 600 ? '':'pa-3'"
					style="overflow: hidden auto"
					:style="drawer_widgets ? {maxHeight: ($vuetify.breakpoint.height - 142) + 'px' }: {}"
			>
				<div
						v-show="drawer_widgets || !row.fixed"
						v-for="(row, y) in rows"
						:key="y"
						v-if="row.items.length > 0 || drawer_widgets"
				>
					<v-row align="center" class="mx-0">
						<v-col cols="auto" class="pl-0 pr-1 pt-0 pb-2" :class="rows.length > 1 ? 'handle2':''">
							<v-icon v-if="drawer_widgets" size="20" class="pb-1" :color="rows.length > 1 ? '':'cell3'">mdi-drag-horizontal-variant</v-icon>
							<Button v-else :click="() => rows[y].open = !rows[y].open" icon iconSize="20" carre iconVal="mdi-chevron-up" :classes="rows[y].open ? '':'transition-rotate'"></Button>
						</v-col>
						<v-col class="pl-0 pt-0 pb-2">
							<div v-if="!drawer_widgets" class="paragraph">{{ row.title.toLowerCase() === 'default' ? '' : row.title | firstCapital }}</div>
							<TextField v-else v-model="rows[y].title" />
						</v-col>
						<v-col cols="auto" v-if="drawer_widgets" class="pl-0 pt-0 pb-1">
							<Button :click="() => fixRow(y)" carre iconVal="mdi-anchor" :color="rows[y].fixed ? 'primary':''"></Button>
						</v-col>
						<v-col cols="auto" v-if="drawer_widgets" class="pl-0 pt-0 pb-1">
							<Button :click="() => deleteRow(y)" carre iconVal="mdi-delete" color="error"></Button>
						</v-col>
						<v-col cols="auto" v-if="drawer_widgets" class="px-0 pt-0 pb-1">
							<Button :click="() => rows[y].open = !rows[y].open" icon iconSize="22" carre iconVal="mdi-chevron-up" :classes="rows[y].open ? '':'transition-rotate'"></Button>
						</v-col>
					</v-row>
					<v-expand-transition>
						<draggable
								v-show="row.open"
								v-model="row.items"
								class="gridcontainer mb-2 transition-cubic text-center"
								ghost-class="ghost"
								handle=".handle"
								:group="{ name: 'widgets' }"
								:style="{
									gridTemplateColumns: 'repeat('+ cols + ', '+ unit +'px)',
									gridTemplateRows: unit + 'px',
									gridAutoRows: unit + 'px'
								}"
						>
							<Widget
									v-for="(widget, x) in row.items"
									:key="widget.id"
									:edit="drawer_widgets"
									:widget="widget"
									:cols="cols"
									path=""
									@delete="deleteWidget(x, y)"
							/>
						</draggable>
					</v-expand-transition>
				</div>
			</draggable>

			<v-divider class="my-3 mx-6" v-show="drawer_widgets && rows.length > 0" />

			<v-row class="mx-0" no-gutters justify="center" v-show="drawer_widgets">
				<v-col class="text-center">
					<Button color="primary" :label="$t('single_trad.Home.add_row')" iconVal="mdi-plus" iconPosition="right" :click="() => rows.push({
						title: 'Default',
						fixed: false,
						open: true,
						items: []
					})"
					/>
					<div class="sub-title-2 mt-3">{{ $t('single_trad.Home.sub_title3') }}</div>
				</v-col>
			</v-row>
		</div>
		<div v-else-if="base_widgets !== null && base_widgets.length === 0 && ready" class="fill-height">
			<div style="display: flex; flex-direction: column; align-items: center;justify-content: center" class="fill-height">
				<div class="title-1 py-5">Bienvenue dans votre tableau de bord NUVEAPaaS !</div>
				<v-img style="max-height: 500px" contain :src="require('@/assets/dashboard.png')" />
				<div class="py-8 sub-title-2">Un espace dédié à l'analyse et la gestion de vos besoins.</div>
			</div>
		</div>
		<div v-else class="fill-height d-flex" style="justify-content: center; align-items: center">
			<v-progress-circular indeterminate size="40" color="primary"/>
		</div>
	</v-col>
</template>

<script>
	export default {
		name: "Home",
		props: ['pevents'],
		components: {
			draggable: () => import('vuedraggable'),
			Widget: () => import('@/components/Widget.vue'),
			TextField: () => import('@/components/fields/TextField.vue'),
			Button: () => import('@/components/ui/Button.vue'),
		},
		data(){
			return {
				unit: 0,
				cols: 0,
				rows: [
					{
						title: 'Default',
						fixed: false,
						open: true,
						items: []
					}
				],
				ready: false,
				base_widgets: null
			}
		},
		computed: {
			drawer_widgets: {
				get(){
					return this.$store.getters['dashboard/drawer_widgets']
				},
				set(val) {
					this.$store.commit('dashboard/CHANGE_DRAWER_WIDGETS_VAL', val)
				}
			},
			fixed_row(){
				return this.rows.find(e => e.fixed === true)
			},
			drawer(){
				return this.$store.getters['dashboard/drawer']
			},
			drawer_mini(){
				return this.$store.getters['dashboard/drawer_mini']
			}
		},
		watch: {
			drawer_widgets(val){
				if(!val){
					if (this.rows.length === 0) {
						this.rows = [
							{
								title: 'Default',
								fixed: false,
								open: true,
								items: []
							},
						]
						this.$store.commit("profile/CHANGE_WIDGETS_VAL", this.rows)
					} else {
						this.$store.commit("profile/CHANGE_WIDGETS_VAL", this.rows)
					}
				}
			},
			base_widgets(val){
				if(val.length > 0){
					let commit = false

					if(Object.keys(this.$store.getters['profile/widgets']).length > 0) {
						this.rows = this.$store.getters['profile/widgets'].map((r, y) => {
							r.items = r.items.map((e, x) => {
								let wid = this.base_widgets.find(f => f.id === e.id)

								if (wid) {
									wid.display_rules = e.display_rules
									if (JSON.stringify(e) !== JSON.stringify(wid)) {
										commit = true
									}
									return wid
								}
							})
							return r
						})
						if (commit) {
							this.$store.commit("profile/CHANGE_WIDGETS_VAL", this.rows)
						}
					}
				}
			}
		},
		methods: {
			gridContainer(e){
				if(this.$vuetify.breakpoint.width < 600){
					this.cols = 1
					this.unit = (e[0].target.clientWidth - 2 * 8) / this.cols
					return
				}
				else if(this.$vuetify.breakpoint.width >= 600 && this.$vuetify.breakpoint.width < 960){
					this.cols = 14
				}
				else if(this.$vuetify.breakpoint.width >= 960 && this.$vuetify.breakpoint.width < 1300){
					this.cols = 18
				}
				else if(this.$vuetify.breakpoint.width >= 1300 && this.$vuetify.breakpoint.width < 1500){
					this.cols = 20
				}
				else if(this.$vuetify.breakpoint.width >= 1500 && this.$vuetify.breakpoint.width < 1904){
					this.cols = 24
				}
				else if(this.$vuetify.breakpoint.width >= 1904){
					this.cols = 28
				}

				this.unit = (e[0].target.clientWidth - 5 * 8 - (this.cols - 1) * 8) / this.cols
			},
			deleteRow(y){
				this.pevents.notify('widget2delete', this.rows[y].items)
				this.rows.splice(y, 1)
			},
			deleteWidget(x, y) {
				this.pevents.notify('widget2delete', [this.rows[y].items[x]])
				this.rows[y].items.splice(x, 1)
			},
			fixRow(index){
				this.rows.forEach((r, i) => {
					if(i === index){
						r.fixed = !r.fixed
					}
					else {
						r.fixed = false
					}
				})
			},
			setUpHooks(){
				this.pevents.wait('update-app', () => {
					this.rows = this.$store.getters['profile/widgets'] || [
						{
							title: 'Default',
							fixed: false,
							open: true,
							items: []
						}
					]
				})

				this.pevents.wait('base-widget', (val) => {
					this.base_widgets = val
					this.ready = true
				})
			}
		},
		created(){
			this.setUpHooks()
		},
		mounted() {
			new ResizeObserver(this.gridContainer).observe(this.$refs.container)
		}
	}
</script>

<style scoped>
	.handle2 {
		cursor: grab;
	}
	.ghost {
		box-shadow: 0 0 0 2px rgba(0, 120, 255, 0.5) inset;
		background-color: rgba(0, 120, 255, 0.1) !important;
	}

	.ghost >>> *:first-child {
		box-shadow: 0 0 0 2px rgba(0, 120, 255, 0.5) inset;
		background-color: rgba(0, 120, 255, 0.1) !important;
	}

	.ghost >>> * {
		display: none;
	}

	.gridcontainer {
		display: grid;
		grid-gap: 8px;
		justify-content: center;
	}
</style>
